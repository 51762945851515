import { RecordListTable } from 'components/record-list-screen/table';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import {
  ColumnConfig,
  ColumnsConfig
} from 'components/record-list-screen/types';
import { Body } from 'components/text/body';
import { orderBy as orderByFunc } from 'lodash';
import { OrderDir } from 'components/record-list-screen/utils/use-order-by';
import { ActionMenuItem } from 'view/components/action-menu/core';
import Box from '@rexlabs/box';
import sessionModel from 'data/models/custom/session';
import { useModelState } from '@rexlabs/model-generator';
import { usePermissions } from 'hooks/use-permissions';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import DropZoneBox from './drop-zone-box';
import useDropFiles, { AddDocumentPayload } from 'hooks/use-drop-files';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS } from 'shared/theme';
import PaddingBox from 'src/view/components/padding-box';
import { UserItem } from 'data/models/value-lists/account-users';
import { EllipsisCell } from 'components/record-list-screen/cells/ellipsis-cell';
import dayjs from 'dayjs';
import FileUploadInput from '@rexlabs/file-upload-input';
import { IconButton } from 'src/view/components/button';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { ACCEPTED_EXTENSIONS } from './constants';

const styles = StyleSheet({
  container: {
    position: 'relative',
    minHeight: 119
  },
  empty: {
    height: 95,
    border: `1px dashed ${COLORS.SAND_DARK}`,
    margin: '0 !important'
  },
  hiddenUploader: {
    display: 'none'
  }
});

export interface DocumentItem {
  description: string;
  system_created_user?: UserItem;
  system_ctime: number;
  system_modified_user?: UserItem;
  system_modtime: number;
  system_size_mb?: number;
  uri: string;
  id: number | string;
  _id: number | string;
  _url: string;
  _destroy?: boolean;
  index?: number;
}

export interface DocumentsTableProps {
  documents: DocumentItem[];
  actionMenu: ActionMenuItem[];
  addDocuments: (documents: AddDocumentPayload[]) => void;
  canAdd: boolean;
  columns?: ColumnsConfig;
  maxFileSize?: number;
}

const DEFAULT_MAX_FILE_SIZE = 30 * 1024 * 1024; // 30 mb

const DocumentsTable = ({
  documents,
  actionMenu,
  addDocuments,
  canAdd,
  columns,
  maxFileSize = DEFAULT_MAX_FILE_SIZE
}: DocumentsTableProps) => {
  const fileUploadRef = useRef<() => void>();
  const s = useStyles(styles);
  const session = useModelState(sessionModel);
  const { check } = usePermissions();
  const { isUploading, progressText, isDragging, dropZone, uploadFiles } =
    useDropFiles({
      addDocuments,
      canAdd,
      maxFileSize
    });

  const [order, setOrder] = useState<{
    orderBy: string | null;
    orderDir: OrderDir | null;
  }>({
    orderBy: 'system_ctime',
    orderDir: 'asc'
  });

  const handleUploadFiles = async (files: FileList | null) => {
    if (!files || files.length === 0) return;
    try {
      await uploadFiles(files);
      fileUploadRef.current?.();
    } catch (error) {
      fileUploadRef.current?.();
    }
  };

  const sortedDocuments = useMemo(() => {
    const { orderBy, orderDir } = order;
    const filtered = documents.filter((document) => !document._destroy);
    if (!orderBy || !orderDir) return filtered;
    return orderByFunc(filtered, [orderBy], [orderDir]);
  }, [documents, order]);

  const defaultColumns: ColumnConfig<DocumentItem>[] = useMemo(() => {
    return [
      {
        id: 'description',
        label: 'filename',
        forced: true,
        width: 387,
        Cell: EllipsisCell,
        cellProps: {
          items: (row: DocumentItem) => {
            // If there is no system_created_user it means it is new and the owner is the current user
            const isUserOwner = row.system_created_user
              ? row.system_created_user.id === session.user_details.id
              : true;

            const hasUpdateRights = isUserOwner
              ? check('documents.update_any') ||
                check('documents.update_created')
              : check('documents.update_any');

            return actionMenu.filter((action) => {
              // Edit & Delete actions require update privileges
              const actionRequiresUpdatePrivileges =
                action.checkRights === '{{updateDocuments}}';

              if (actionRequiresUpdatePrivileges) return hasUpdateRights;

              return true;
            });
          }
        }
      },
      {
        id: 'date_uploaded',
        label: 'date uploaded',
        forced: true,
        sortable: 'system_ctime',
        selector: (row) => dayjs.unix(row.system_ctime).format('D MMM YYYY'),
        rightAlign: true,
        cellProps: {
          fullWidth: true
        }
      }
    ];
  }, [actionMenu, check, session.user_details.id]);

  return (
    <Box pt={4} pr={25} ref={dropZone} {...s('container')}>
      <DropZoneBox
        isDragging={isDragging}
        isUploading={isUploading}
        progressText={progressText}
      />
      {canAdd && (
        <FileUploadInput
          shouldAllowMultiple
          // FileUploadInput file type check doesn't handle irregular file extensions like 'PNG', 'PnG', 'Png'
          // We handle the accept type on the InputContainer below
          acceptExtensions={'*'}
          acceptTypes={'*'}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleUploadFiles(e.target.files)
          }
          // We handle it on uploadFiles
          maxFileSize={null}
        >
          {({
            triggerInput,
            InputContainer,
            inputProps,
            dragEvents,
            removeFiles
          }) => {
            fileUploadRef.current = removeFiles;
            return (
              <Box flex alignItems='center'>
                <Body semibold dark large>
                  Documents
                </Body>
                <IconButton
                  circle
                  red
                  Icon={ICONS.ADD_MEDIUM_THICK}
                  onClick={triggerInput}
                >
                  upload document
                </IconButton>

                <span {...s('hiddenUploader')}>
                  <InputContainer {...inputProps} {...dragEvents} />
                </span>
              </Box>
            );
          }}
        </FileUploadInput>
      )}
      <div style={{ opacity: isDragging && !isUploading ? 0.2 : 1 }}>
        <RecordListTable
          items={sortedDocuments}
          columns={columns || defaultColumns}
          visibleColumns={(columns || defaultColumns).map((c) => c.id)}
          setVisibleColumns={() => null}
          hasSelection={false}
          orderBy={order.orderBy || undefined}
          orderDir={order.orderDir || undefined}
          setOrderBy={(orderBy: string, orderDir: OrderDir) => {
            setOrder({ orderBy, orderDir });
          }}
          isLoading={false}
          LoadingView={() => null}
          EmptyView={() => (
            <PaddingBox
              light
              flexDirection='column'
              alignItems='center'
              {...s('empty')}
            >
              <Icon
                type={ICONS.UPLOAD_NEW}
                width={24}
                height={24}
                style={{
                  color: COLORS.SAND_DARK
                }}
                hasControlledColor={false}
              />
              <Body small semibold normal>
                Drag & drop file
              </Body>
            </PaddingBox>
          )}
          variant={'compact'}
          colorScheme={'light'}
        />
      </div>
    </Box>
  );
};

export default DocumentsTable;
