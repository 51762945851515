/* eslint-disable max-lines */
import { COLORS } from './colors';
import { FONT } from './font';
import { UTILS } from './misc';

const NAV_MENU = {
  TEXTS: {
    TIER_ONE: {
      DESKTOP: {
        fontFamily: FONT.FAMILY.PROXIMA_NOVA,
        fontWeight: FONT.WEIGHTS.REGULAR,
        lineHeight: FONT.LINE_HEIGHTS.NAV_MENU,
        fontSize: FONT.SIZES.NAV_MENU.TIER_ONE,
        color: COLORS.NAV_MENU.GREY_DARK
      },
      MOBILE: {
        fontFamily: FONT.FAMILY.PROXIMA_NOVA,
        fontWeight: FONT.WEIGHTS.REGULAR,
        lineHeight: FONT.LINE_HEIGHTS.NAV_MENU,
        fontSize: FONT.SIZES.NAV_MENU.TIER_ONE,
        color: COLORS.NAV_MENU.GREY_DARK
      }
    }
  }
} as const;

export const NEW_TEXTS = {
  HEADING: {
    lineHeight: '28px',
    fontSize: '27px',
    fontWeight: 600,
    color: COLORS.DARK_GREY
  },

  HEADING_1: {
    lineHeight: '40px',
    fontSize: '36px',
    fontWeight: 900
  },

  HEADING_2: {
    lineHeight: '20px',
    fontSize: '17px'
  },

  HEADING_3: {
    lineHeight: '16px',
    fontSize: '16px'
  },

  HEADING_4: {
    lineHeight: '14px',
    fontSize: '12px'
  },

  SUBHEADING: {
    lineHeight: '20px',
    fontSize: '17px',
    fontWeight: 900,
    color: COLORS.DARK_GREY
  },

  CONTEXT: {
    lineHeight: '14px',
    fontSize: '12px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.BORDERS.GREY
  },

  CONTEXT_HEADING: {
    lineHeight: '18px',
    fontSize: '15px'
  },

  SECTIONHEADING: {
    lineHeight: '13px',
    fontSize: '13px',
    fontWeight: 600,
    color: COLORS.DARK_GREY
  },

  RIGHTBAR_HEADING: {
    lineHeight: '16px',
    fontSize: '17px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.DARK_GREY
  },

  RIGHTBAR_STATUSLABEL: {
    lineHeight: '20px',
    fontSize: '12px',
    fontWeight: FONT.WEIGHTS.REGULAR,
    color: COLORS.DARK_GREY,
    cursor: 'pointer'
  },

  PLACEHOLDER_HEADING: {
    lineHeight: '20px',
    fontSize: '17px',
    fontWeight: 600,
    color: COLORS.PRIMARY.SAND,
    fontStyle: 'italic'
  },

  PLACEHOLDER: {
    lineHeight: '17px',
    fontSize: '14px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.BORDERS.GREY
  },

  BODY: {
    lineHeight: '17px',
    fontSize: '14px',
    fontWeight: 600,
    color: COLORS.PRIMARY.SAND,
    fontStyle: 'italic'
  },

  HINT: {
    lineHeight: '17px',
    fontSize: '14px',
    fontWeight: 500,
    color: COLORS.BORDERS.GREY,
    fontStyle: 'italic'
  },

  EMPTY_STATE_HINT: {
    lineHeight: '17px',
    fontSize: '17px',
    fontWeight: 600,
    color: COLORS.PRIMARY.SAND,
    fontStyle: 'italic'
  },

  SMALL: {
    fontSize: '12px'
  },

  LARGE: {
    fontSize: '16px',
    lineHeight: '20px'
  },

  TABLE_DATA: {
    lineHeight: '17px',
    fontSize: '14px',
    fontWeight: FONT.WEIGHTS.MEDIUM,
    fontFamily: FONT.FAMILY.PROXIMA_NOVA,
    color: 'rgb(0,0,0,0.75)'
  },

  BODY_DARK: {
    fontWeight: FONT.WEIGHTS.REGULAR,
    color: COLORS.DARK_GREY,
    fontStyle: 'normal'
  },

  SUBTEXT: {
    lineHeight: '20px',
    fontSize: '12px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.DARK_GREY
  },

  INFORMATIVE_SUBTEXT: {
    fontSize: '14px',
    fontWeight: FONT.WEIGHTS.REGULAR,
    fontStyle: 'italic'
  },

  INPUT: {
    lineHeight: '17px',
    fontSize: '14px',
    fontWeight: FONT.WEIGHTS.MEDIUM,
    fontFamily: FONT.FAMILY.PROXIMA_NOVA,
    color: '#404040'
  },

  INPUT_LABEL: {
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: FONT.WEIGHTS.REGULAR,
    color: COLORS.DARK_GREY
  },

  FORM_FIELD: {
    lineHeight: '19px',
    fontSize: '12px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.COAL_GREY
  },

  LINK: {
    lineHeight: '16px',
    fontSize: '13px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.DARK_GREY,
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  RECORD_LINK: {
    lineHeight: '20px',
    fontSize: '14px',
    textDecoration: 'none'
  },

  NORMAL: {
    fontStyle: 'normal'
  },

  REGULAR: {
    fontWeight: FONT.WEIGHTS.REGULAR
  },

  MEDIUM: {
    fontWeight: FONT.WEIGHTS.MEDIUM
  },

  SEMIBOLD: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  BOLD: {
    fontWeight: FONT.WEIGHTS.BOLD
  },

  UNDERLINE: {
    textDecoration: 'underline'
  },

  TAG: {
    lineHeight: '18px',
    fontSize: '13px',
    fontWeight: 600,
    color: COLORS.TEXTS.TAG
  },

  TAG_LIGHT: {
    lineHeight: '17px',
    fontSize: '14px',
    fontWeight: 600,
    color: COLORS.WHITE
  },

  ITALIC: {
    fontStyle: 'italic'
  },

  INFORMATIVE: {
    color: COLORS.PRIMARY.INFORMATIVE,
    fontWeight: FONT.WEIGHTS.REGULAR
  },

  TRUNCATED: {
    ...UTILS.truncate
  },

  // NOTE: merge conflict resolution?!
  // Disabled this cause there is another INPUT key already on here and I'm
  // not sure about intentions, left it in here commented out for later
  // reference ... if you're from the future, feel free to remove as needed ;)
  // INPUT: {
  //   lineHeight: '17px',
  //   fontSize: '14px',
  //   fontWeight: 400,
  //   color: COLORS.TEXTS.TAG
  // },

  OPTION_LABEL: {
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 600,
    color: COLORS.SLATE.MIDNIGHT
  },

  OPTION_DETAIL: {
    lineHeight: '18px',
    fontSize: '13px',
    fontWeight: 400,
    color: COLORS.SLATE.DARK
  },

  OPTION_DETAIL_ACTIVE: {
    lineHeight: '18px',
    fontSize: '13px',
    fontWeight: 400,
    color: COLORS.SLATE.MIDNIGHT
  },

  BLUE: {
    color: COLORS.PRIMARY.BLUE
  },

  WHITE: {
    color: COLORS.WHITE
  },

  UNDECORATED: {
    textDecoration: 'none'
  },

  LINE_THROUGH: {
    textDecoration: 'line-through'
  },

  DIALOG_SECTION_HEADING: {
    lineHeight: '20px',
    fontSize: '17px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    fontFamily: FONT.FAMILY.PROXIMA_NOVA,
    color: '#424242'
  },

  SHORT: {
    lineHeight: '14px'
  },

  LIGHT: {
    color: COLORS.PRIMARY.SAND
  },

  DARK: {
    color: COLORS.DARK_GREY
  },

  BLACK: {
    color: COLORS.BLACK
  },

  LINKED: {
    cursor: 'pointer',
    color: COLORS.PRIMARY.BLUE
  },

  UNLINKED: {
    cursor: 'auto'
  },

  SLATE: {
    color: COLORS.PRIMARY.SLATE
  },

  SLATE_DARK: {
    color: COLORS.PRIMARY.SLATE_DARK
  },
  RED: {
    color: COLORS.DASHBOARDS.DECREASE
  },
  GREEN: {
    color: COLORS.DASHBOARDS.INCREASE
  },

  LOW: {
    lineHeight: '16px'
  },

  CLICKABLE: {
    cursor: 'pointer'
  },

  NEWLINE: {
    whiteSpace: 'pre-line'
  },

  VERTICAL_ALIGN: {
    display: 'flex',
    alignItems: 'center'
  }
} as const;

export const TEXTS = {
  APP_SEARCH: {
    RECORD_NAME: {
      lineHeight: '17px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      color: COLORS.DARK_GREY,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingBottom: '3px'
    },
    META_DATA: {
      lineHeight: '14px',
      fontSize: FONT.SIZES.SMALL,
      fontWeight: FONT.WEIGHTS.REGULAR,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingBottom: '3px'
    },
    SHOW_ALL: {
      fontSize: FONT.SIZES.REGULAR,
      color: COLORS.BLUE_GREY
    },
    ID_NUMBER: {
      fontSize: FONT.SIZES.SMALL,
      color: COLORS.BLACK_30_OPACITY
    },
    HEADING: {
      fontSize: FONT.SIZES.SMALL,
      fontWeight: FONT.WEIGHTS.REGULAR,
      letterSpacing: FONT.SPACING.LARGE,
      color: COLORS.BLACK_40_OPACITY,
      textTransform: FONT.TRANSFORM.UPPERCASE
    },
    MENU_HEADING: {
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      color: COLORS.BLUE_GREY
    }
  },
  NAV_MENU: {
    TIER_ONE_DEFAULT: {
      ...NAV_MENU.TEXTS.TIER_ONE.DESKTOP
    },
    TIER_ONE_HOVER: {
      ...NAV_MENU.TEXTS.TIER_ONE.DESKTOP,
      color: COLORS.BLACK,
      backgroundColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
    },
    TIER_ONE_ACTIVE: {
      ...NAV_MENU.TEXTS.TIER_ONE.DESKTOP,
      fontWeight: FONT.WEIGHTS.SEMIBOLD
    },
    TIER_TWO_DEFAULT: {
      ...NAV_MENU.TEXTS.TIER_ONE.DESKTOP,
      color: COLORS.NAV_MENU.GREY_DARK,
      fontSize: FONT.SIZES.REGULAR
    },
    TIER_TWO_HEADING: {
      ...NAV_MENU.TEXTS.TIER_ONE.DESKTOP,
      fontSize: FONT.SIZES.SMALL,
      fontWeight: FONT.WEIGHTS.MEDIUM,
      color: 'currentColor',
      letterSpacing: '0.70px'
    },
    TIER_TWO_HOVER: {
      ...NAV_MENU.TEXTS.TIER_ONE.DESKTOP,
      color: COLORS.BLACK,
      fontSize: FONT.SIZES.REGULAR,
      backgroundColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
    },
    TIER_TWO_ACTIVE: {
      ...NAV_MENU.TEXTS.TIER_ONE.DESKTOP,
      fontSize: FONT.SIZES.REGULAR,
      fontWeight: FONT.WEIGHTS.SEMIBOLD
    },
    MOBILE: {
      TIER_TWO_HEADING: {
        ...NAV_MENU.TEXTS.TIER_ONE.MOBILE,
        fontSize: FONT.SIZES.XL
      }
    }
  },

  // Tmp fixed styles
  HEADING_OVERLAY: {
    color: '#b4b1a9',
    fontSize: '42px',
    lineHeight: '42px',
    fontWeight: '300',
    marginBottom: '15px'
  },

  BODY_OVERLAY: {
    color: '#b4b1a9',
    fontSize: '14px',
    fontStyle: 'italic',
    marginBottom: '15px',
    lineHeight: '20px'
  },

  BODY_DIALOG: {
    lineHeight: '17px',
    fontSize: '14px',
    color: COLORS.DARK_GREY
  },

  // TODO: Only use the TEXTS from this line and below
  // Any of the above TEXTS should be updated to use the following if you run into them.

  // TODO: Change the colors to use COLORS after sketch is updated from using non linked colors
  HEADERS: {
    FEATURE_MODAL_HEADING: {
      lineHeight: '42px',
      fontSize: '42px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#B4B1A9'
    },
    HEADING_1: {
      lineHeight: '28px',
      fontSize: '27px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    HEADING_1_LINK: {
      lineHeight: '28px',
      fontSize: '27px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: COLORS.PRIMARY.BLUE
    },
    HEADING_2: {
      lineHeight: '20px',
      fontSize: '17px',
      fontWeight: FONT.WEIGHTS.BOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    // This one is new in the sketch, TODO: audit sketch foundations
    HEADER_2: {
      lineHeight: '24px',
      fontSize: '24px',
      fontWeight: FONT.WEIGHTS.BOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    HEADING_2_LINK: {
      lineHeight: '17px',
      fontSize: '17px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: COLORS.PRIMARY.BLUE
    },
    HEADING_3: {
      lineHeight: '13px',
      fontSize: '13px',
      fontWeight: FONT.WEIGHTS.BOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    HEADING_4: {
      lineHeight: '14px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    SUBHEADING_1: {
      lineHeight: '20px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#929088'
    },
    SUBHEADING_2: {
      lineHeight: '20px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#B4B1A9'
    },
    DEFAULT_MODAL_HEADING: {
      lineHeight: '46px',
      fontSize: '17px',
      fontWeight: FONT.WEIGHTS.BOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    DEFAULT_MODAL_SUBHEADING: {
      lineHeight: '19px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.BOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    }
  },

  GLOBAL_NAVIGATION: {
    AGENCY_NAME: {
      lineHeight: '26px',
      fontSize: '20px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    SEARCH_PLACEHOLDER_IDLE: {
      lineHeight: '24px',
      fontSize: '16px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    SEARCH_PLACEHOLDER_ACTIVE: {
      lineHeight: '24px',
      fontSize: '16px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#B4B4B4'
    },
    TIER_1_ITEM_ACTIVE: {
      lineHeight: '22px',
      fontSize: '15px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#000000'
    },
    TIER_1_ITEM_NOT_ACTIVE: {
      lineHeight: '22px',
      fontSize: '15px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#000000'
    },
    TIER_2_HEADER: {
      lineHeight: '22px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: COLORS.PRIMARY.SLATE
    },
    TIER_2_ITEM_ACTIVE: {
      lineHeight: '22px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#000000'
    },
    TIER_2_ITEM_NOT_ACTIVE: {
      lineHeight: '22px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#000000'
    }
  },

  DASHBOARD_NAVIGATION: {
    NAV_ITEM: {
      lineHeight: '20px',
      fontSize: '17px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    NAV_ITEM_ACTIVE: {
      lineHeight: '20px',
      fontSize: '17px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    SUB_NAV_ITEM: {
      lineHeight: '20px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    }
  },

  SETTINGS_NAVIGATION: {
    MENU_ITEM_SELECTED: {
      lineHeight: '19px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    MENU_ITEM: {
      lineHeight: '19px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    BREADCRUMBS_LINK: {
      lineHeight: '24px',
      fontSize: '24px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    }
  },

  CONTENT_NAVIGATION: {
    TAB: {
      lineHeight: '16px',
      fontSize: '13px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    STEP: {
      lineHeight: '15px',
      fontSize: '15px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    }
  },

  BUTTON: {
    CTA_WHITE_LARGE: {
      lineHeight: '57px',
      fontSize: '16px',
      fontWeight: FONT.WEIGHTS.BOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    CTA_WHITE_REGULAR: {
      lineHeight: '16px',
      fontSize: '13px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    CTA_SAND_LARGE: {
      lineHeight: '25px',
      fontSize: '21px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#929088'
    },
    CTA_SAND_REGULAR: {
      lineHeight: '17px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#929088'
    },
    CTA_SAND_SMALL: {
      lineHeight: '19px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#B2B1AB'
    },
    CTA_DARK_GREY: {
      lineHeight: '32px',
      fontSize: '13px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    CTA_DARK_GREY_SMALL: {
      lineHeight: '19px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    CTA_BLUE: {
      lineHeight: '16px',
      fontSize: '13px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: COLORS.PRIMARY.BLUE,
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },

  CONTENT: {
    DEFAULT_TEXT: {
      lineHeight: '20px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#929088',
      fontStyle: 'italic'
    },
    INFORMATIVE_SUBTEXT_1: {
      lineHeight: '20px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040',
      fontStyle: 'italic'
    },
    INFORMATIVE_SUBTEXT_2: {
      lineHeight: '25px',
      fontSize: '21px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#929088',
      fontStyle: 'italic'
    },
    DESCRIPTIVE_SUBTEXT_1: {
      lineHeight: '20px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    DESCRIPTIVE_SUBTEXT_2: {
      lineHeight: '19px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#B4B1A9'
    },
    RECORD_LINK: {
      lineHeight: '14px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: COLORS.PRIMARY.BLUE
    },
    DESCRIPTIVE_SUBTEXT_2_LINKED: {
      lineHeight: '19px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: COLORS.PRIMARY.BLUE
    },
    DESCRIPTIVE_SUBTEXT_3: {
      lineHeight: '14px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    FORM_FIELD_LABEL_1: {
      lineHeight: '19px',
      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    FORM_FIELD_LABEL_2: {
      lineHeight: '20px',
      fontSize: '17px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#FFFFFF'
    },
    INPUT_TEXT_SEMIBOLD: {
      lineHeight: '17px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#424242'
    },
    GLOBAL_SEARCH_INPUT_TEXT: {
      lineHeight: '24px',
      fontSize: '16px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#424242'
    },
    INPUT_TEXT_REGULAR: {
      lineHeight: '17px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#424242'
    },
    INPUT_TEXT_MEDIUM: {
      lineHeight: '17px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    INPUT_TEXT_LARGE: {
      lineHeight: '32px',
      fontSize: '27px',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#404040'
    },
    PLACEHOLDER: {
      // firefox hates line-height in placeholders
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      color: '#B4B1A9',
      fontStyle: 'italic'
    },
    TABLES: {
      TAB: {
        lineHeight: '16px',
        fontSize: '13px',
        fontWeight: FONT.WEIGHTS.SEMIBOLD,
        fontFamily: FONT.FAMILY.PROXIMA_NOVA,
        color: '#FFFFFF'
      },
      SECTION_HEADER_1: {
        lineHeight: '17px',
        fontSize: '14px',
        fontWeight: FONT.WEIGHTS.BOLD,
        fontFamily: FONT.FAMILY.PROXIMA_NOVA,
        color: '#FFFFFF'
      },
      SECTION_HEADER_2: {
        lineHeight: '20px',
        fontSize: '14px',
        fontWeight: FONT.WEIGHTS.BOLD,
        fontFamily: FONT.FAMILY.PROXIMA_NOVA,
        color: '#000000'
      },
      INPUT_TEXT_MEDIUM: {
        lineHeight: '20px',
        fontSize: '14px',
        fontWeight: FONT.WEIGHTS.MEDIUM,
        fontFamily: FONT.FAMILY.PROXIMA_NOVA,
        color: '#404040'
      },
      HIGH_IMAGE_NUMBER: {
        lineHeight: '14px',
        fontSize: '14px',
        fontWeight: FONT.WEIGHTS.BOLD,
        fontFamily: FONT.FAMILY.PROXIMA_NOVA,
        color: '#C11D17'
      }
    }
  },

  ...NEW_TEXTS
} as const;
