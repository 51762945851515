import _ from 'lodash';
import { StyleSheet } from '@rexlabs/styling';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import {
  complianceQueries,
  ComplianceStatus
} from 'src/features/compliance/data/compliance-query-model';
import { Body } from 'src/components/text/body';
import { SubHeading } from 'src/components/text/sub-heading';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS } from 'src/theme';
import { COLORS as SHARED_COLORS } from 'shared/theme';
import Spinner from 'shared/components/spinner';
import { useDialog } from 'src/hooks/use-dialog';
import { usePermissions } from 'hooks/use-permissions';
import { ListingStub } from 'data/models/entities/listings';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

interface ComplianceSidebarItemsProps {
  recordId: string;
  service?: string;
  listing?: ListingStub;
  recordData?: any;
  recordType?: string;
}

const styles = StyleSheet({
  container: {
    marginBottom: '20px',
    marginTop: '20px'
  },
  section: {
    marginBottom: '10px'
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  statusDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#e74c3c',
    marginLeft: '8px'
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  item: {
    display: 'flex',
    marginBottom: '4px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    marginRight: '9px',
    marginTop: '-2px',
    marginLeft: '-1px',
    width: '14px'
  },
  archiveIcon: {
    display: 'inline-flex',
    marginLeft: '4px',
    marginTop: '2px'
  },
  itemContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemHeader: {
    marginBottom: '-1px',
    '& p': {
      borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
      lineHeight: '0.8 !important',
      width: 'fit-content'
    }
  },
  viewToggle: {
    color: '#999',
    cursor: 'pointer',
    display: 'flex',
    marginTop: '5px'
  },
  spinner: {
    marginTop: '10px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const STATUS_CONFIG = {
  verified_with_expiry_date: {
    color: SHARED_COLORS.STATUS.GREEN,
    icon: ICONS.TICK,
    iconSize: 20,
    shouldAlert: false
  },
  verified_no_expiry_date: {
    color: SHARED_COLORS.STATUS.GREEN,
    icon: ICONS.TICK,
    iconSize: 20,
    shouldAlert: false
  },
  completed: {
    color: SHARED_COLORS.STATUS.GREEN,
    icon: ICONS.TICK,
    iconSize: 20,
    shouldAlert: false
  },
  submitted: {
    color: SHARED_COLORS.STATUS.GREEN,
    icon: ICONS.TICK,
    iconSize: 20,
    shouldAlert: false
  },
  need_verification: {
    color: SHARED_COLORS.STATUS.RED,
    icon: ICONS.WARNING,
    shouldAlert: true
  },
  expires_soon: {
    color: SHARED_COLORS.STATUS.ORANGE,
    icon: ICONS.WARNING_FILLED,
    shouldAlert: true
  },
  rejected: {
    color: SHARED_COLORS.STATUS.RED,
    icon: ICONS.WARNING,
    shouldAlert: true
  },
  expired: {
    color: SHARED_COLORS.STATUS.RED,
    icon: ICONS.WARNING,
    shouldAlert: true
  },
  not_added: {
    color: SHARED_COLORS.STATUS.RED,
    icon: ICONS.WARNING,
    shouldAlert: true
  }
};

const getStatusIcon = (status: string) => {
  return STATUS_CONFIG[status]?.icon || ICONS.WARNING;
};

const getStatusIconSize = (status: string) => {
  return STATUS_CONFIG[status]?.iconSize || 14;
};

const getStatusColor = (status: string) => {
  return STATUS_CONFIG[status]?.color || SHARED_COLORS.STATUS.RED;
};

const MAX_ITEMS = 5;

export const ComplianceSidebarItems: React.FC<ComplianceSidebarItemsProps> = ({
  recordId,
  service,
  listing,
  recordType,
  recordData
}) => {
  const [expanded, setExpanded] = useState(false);
  const complianceDialog = useDialog('complianceEntryDialog');
  const manageEpcDialog = useDialog('manageEpc');
  const idVerificationDialog = useDialog('idVerification');
  const editSubstantiation = useDialog('editSubstantiation');
  const substantiationsList = useDialog('substantiationsList');
  const amlCheck = useDialog('amlCheck');
  const { hasAddon } = usePermissions();
  const isArchivedMode =
    listing?.system_listing_state &&
    listing?.system_listing_state !== 'current';

  const { data, isLoading, refetch } = useQuery(
    complianceQueries.getComplianceStatuses({
      recordId,
      service
    })
  );

  const complianceStatuses = useMemo(() => {
    return hasAddon('aml_and_id_checks')
      ? data?.filter((r) => r.type !== 'contact_aml_check') // always filter out the form schema contact_aml_check
      : data?.filter((r) => {
          const filteredTypes = [
            'aml',
            'contact_aml_check', // always filter out the form schema contact_aml_check
            'substantiation',
            'contact_id_check'
          ];

          return (
            !filteredTypes.includes(r.type) &&
            !filteredTypes.some((t) => r.type.includes(t))
          );
        });
  }, [data, hasAddon]);

  const shouldShowAlert = () => {
    return complianceStatuses?.some(
      (item) => STATUS_CONFIG[item.status.id]?.shouldAlert
    );
  };

  const customItemHandlers = {
    contact_id_check: (_item) => {
      idVerificationDialog.open({
        id: _item.compliance_entry_id,
        contactId: recordId,
        type: recordType,
        onClose: () => {
          refetch();
        }
      });
    },
    aml: () => {
      amlCheck.open({
        contact: {
          id: recordId,
          type: recordType
        },
        complianceStatus: complianceStatuses?.find(
          (status) => status.type === 'aml'
        ),
        callback: () => {
          refetch();
        }
      });
    },
    substantiation: (item) => {
      const items = item.metadata.substantiations;
      const contact = recordData;
      if (!items.length) {
        editSubstantiation.open({
          listDialog: () => {
            return substantiationsList.open({
              contacts: [{ contact }],
              callback: () => refetch()
            });
          },
          substantiationCount: items.length,
          contacts: [{ contact }],
          callback: () => {
            refetch();
          }
        });
        return;
      }

      if (items.length === 1) {
        editSubstantiation.open({
          listDialog: () => {
            return substantiationsList.open({
              contacts: [{ contact }],
              callback: () => refetch()
            });
          },
          substantiationCount: items.length,
          id: items[0].id,
          callback: () => {
            refetch();
          }
        });
        return;
      }

      substantiationsList.open({
        contacts: [{ contact }],
        callback: () => {
          refetch();
        }
      });
    },
    epc: (_item) => {
      manageEpcDialog.open({
        _id: recordId,
        listing: listing,
        ...recordData,
        callback: (result) => {
          window.Rex2FrameWindow.AVM.events.trigger('reloadEPC', result.data);
          refetch();
        }
      });
    }
  } as const satisfies Record<string, (item: ComplianceStatus) => void>;

  const displayedItems = expanded
    ? complianceStatuses
    : complianceStatuses?.slice(0, MAX_ITEMS);

  const hasMoreItems = (complianceStatuses?.length || 0) > MAX_ITEMS;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleItemClick = (item) => {
    const handlerMatch = Object.keys(customItemHandlers).find(
      (k) => item.type === k || item.type.includes(k)
    );
    if (handlerMatch) {
      customItemHandlers[handlerMatch](item);
      return;
    }

    complianceDialog.open({
      complianceEntryId: item.compliance_entry_id,
      complianceType: item.type,
      recordId,
      service,
      listing,
      label: item.label
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SubHeading semibold>
              Compliance
              {shouldShowAlert() && <span className={styles.statusDot}></span>}
            </SubHeading>
          </div>
        </div>
        {isLoading ? (
          <div className={styles.spinner}>
            <Spinner dark extraSmall />
          </div>
        ) : (
          <>
            <ul className={styles.list}>
              {displayedItems?.map((item) => (
                <li
                  key={item.type}
                  className={styles.item}
                  onClick={() => handleItemClick(item)}
                >
                  <div className={styles.itemIcon}>
                    <Icon
                      type={getStatusIcon(item.status.id)}
                      width={getStatusIconSize(item.status.id)}
                      height={getStatusIconSize(item.status.id)}
                      color={getStatusColor(item.status.id)}
                    />
                  </div>
                  <div className={styles.itemContent}>
                    <div className={styles.itemHeader}>
                      <Body small semibold normal dark>
                        {item.label}
                      </Body>
                    </div>
                    <Body small dark regular normal>
                      <span
                        style={{
                          color: getStatusColor(item.status.id),
                          fontSize: 'inherit'
                        }}
                      >
                        {item.status.label}
                        {hasFeatureFlags('compliance') && isArchivedMode && (
                          <span className={styles.archiveIcon}>
                            <Icon
                              style={{
                                color: SHARED_COLORS.GREY_70
                              }}
                              type={ICONS.SYNC_DISABLED}
                            />
                          </span>
                        )}
                      </span>
                    </Body>
                  </div>
                </li>
              ))}
            </ul>
            {hasMoreItems && (
              <div className={styles.viewToggle} onClick={toggleExpanded}>
                <Body small normal underline>
                  {expanded
                    ? 'view less'
                    : `view ${
                        (complianceStatuses?.length || 0) - MAX_ITEMS
                      } more`}
                </Body>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ComplianceSidebarItems;
