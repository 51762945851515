/* eslint-disable max-lines */
/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';

import localForage from 'localforage';
import { persistReducer, persistStore } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import {
  whereaboutsMiddleware,
  whereaboutsReducer
} from '@rexlabs/whereabouts';
import { combineModels } from '@rexlabs/model-generator';

import { apiClientMiddleware, configureStore } from 'shared/utils/redux';
import { setAuthToken } from 'shared/utils/api-client';
import config from 'shared/utils/config';

import { middleware as bridgesMiddleware } from 'data/classic-bridges';
import { bridgeEntityMiddleware } from 'data/classic-bridges/entities';

import collisionAvoidanceMiddleware from 'utils/redux/middleware/collision-avoidance';
import uiMiddleware from 'utils/redux/middleware/ui';
import notificationMiddleware from 'utils/redux/middleware/notification';

import connection, {
  middleware as connectionMiddleware
} from 'shared/data/models/custom/connection';
import auth from 'shared/data/models/custom/auth';
import nav from 'data/models/custom/nav';
import session from 'data/models/custom/session';
import ui from 'data/models/custom/ui';
import recordStream from 'data/models/custom/record-stream';
import pafUpload from 'data/models/custom/paf-upload';
import announcements from 'data/models/custom/announcements';
import washLists from 'data/models/custom/wash-lists';
import collisionAvoidance from 'data/models/custom/collision-avoidance';
import connectableCalendars from 'data/models/custom/connectable-calendars';
import thirdPartyServiceEaPrint from 'data/models/custom/third-party-service-ea-print';
import thirdPartyServiceClickToDial from 'data/models/custom/third-party-service-click-to-dial';
import ThirdPartyServiceHelloSign from 'data/models/custom/third-party-service-hello-sign';
import ThirdPartyServiceDocuSign from 'data/models/custom/third-party-service-docu-sign';
import adminMergeTemplates from 'data/models/custom/admin-merge-templates';
import notification from 'data/models/custom/notification';
import telephony from 'data/models/custom/telephony';
import apiCache from 'data/models/custom/api-cache';
import ThirdPartyServiceSpokeSocial from 'data/models/custom/third-party-service-spoke-social';
import privacySettings from 'data/models/custom/privacy-settings';
import { contactPrivacy } from 'data/models/custom/contact-privacy';
import adminOutboundSettings from 'data/models/custom/admin-outbound-settings';
import dashboardReportingData from 'features/dashboards/data/dashboard-reporting-data';
import dashboardFilter from 'features/dashboards/data/dashboard-filter';
import { persistReportingFilters } from 'features/custom-reporting/data/reporting-filters';
import { customReports } from 'features/custom-reporting/data/custom-reports-model';
import externalAddress from 'data/models/custom/external-address';
import substantiations from 'data/models/entities/substantiations';
import accountSettingsCompliance from 'data/models/custom/account-settings-compliance';
import contacts from 'data/models/entities/contacts';
import contracts from 'features/listings/data/contracts';
import invoices from 'data/models/entities/invoices';
import listings from 'data/models/entities/listings';
import campaigns from 'data/models/entities/campaigns';
import campaignMembers from 'data/models/entities/campaign-members';
import listingPublication from 'data/models/entities/listing-publication';
import postcodes from 'data/models/entities/postcodes';
import userProfile from 'data/models/entities/user-profile';
import addressLookup from 'data/models/entities/address-lookup';
import adminUserValidationRules from 'data/models/entities/user-validations';
import marketLeads from 'data/models/entities/market-leads';
import properties from 'data/models/entities/properties';
import buildings from 'data/models/entities/buildings';
import contactAntiMoneyLaunderingChecks from 'data/models/entities/contact-aml-checks';
import contactIdentityVerifications from 'data/models/entities/contact-identity-verifications';
import adminIdentityDocumentTypes from 'data/models/entities/admin-identity-document-types';
import adminIdentityRequirementsCompanies from 'data/models/entities/admin-identity-requirements-company';
import adminIdentityRequirementsPeople from 'data/models/entities/admin-identity-requirements-people';
import adminAppointmentTypes from 'features/calendar/data/admin-appointment-types';
import adminWorkflows from 'data/models/entities/admin-workflows';
import workflowInstances from 'data/models/entities/workflow-instances';
import adminCampaignTemplates from 'data/models/entities/admin-campaign-templates';
import adminReferralCategories from 'data/models/entities/admin-referral-categories';
import adminBuyerMatch from 'data/models/entities/admin-buyer-match';
import referrals from 'data/models/entities/referrals';
import adminCommGrossStructures from 'data/models/entities/admin-comm-gross-structures';
import calendars from 'data/models/entities/calendars';
import userCalendars from 'data/models/entities/user-calendars';
import calendarEvents from 'data/models/entities/calendar-events';
import chains from 'features/chains/data/chains';
import chainInstances from 'features/chains/data/chain-instances';
import chainLinks from 'features/chains/data/chain-links';
import deals from 'data/models/entities/deals';
import leads from 'data/models/entities/leads';
import projects from 'data/models/entities/projects';
import projectStages from 'data/models/entities/project-stages';
import appraisals from 'data/models/entities/appraisals';
import listingPortalUploads from 'data/models/entities/listing-portal-uploads';
import adminMetricWriteRules from 'data/models/entities/admin-metric-write-rules';
import metrics from 'data/models/entities/metrics';
import adminUserGroups from 'data/models/entities/admin-user-groups';
import embeddedApps from 'data/models/entities/admin-embedded-apps';
import adminDisclaimers from 'data/models/entities/admin-disclaimers';
import notes from 'data/models/entities/notes';
import reminders from 'data/models/entities/reminders';
import feedback from 'data/models/entities/feedback';
import newsletterCampaigns from 'data/models/entities/newsletter-campaigns';
import keyRegister from 'data/models/entities/key-register';
import adminKeyLocations from 'data/models/entities/admin-key-locations';
import mailMerge from 'data/models/entities/mail-merge';
import savedFilters from 'data/models/entities/saved-filters';
import reportingDashboards from 'features/dashboards/data/reporting-dashboards';
import activities from 'data/models/entities/activities-model';

import adminUserValidationServices from 'data/models/system-lists/admin-user-validation-services';
import adminUserValidationTriggerMap from 'data/models/system-lists/admin-user-validation-trigger-map';
import contactCompanyRelnType from 'data/models/system-lists/contact-company-reln-type';
import campaignType from 'data/models/system-lists/campaign-type';
import campaignStatus from 'data/models/system-lists/campaign-status';
import campaignRecordBaseStatus from 'data/models/system-lists/campaign-record-base-status';
import enquiryMethod from 'data/models/value-lists/enquiry-method';
import enquirySource from 'data/models/system-lists/enquiry-source';
import idVerificationDocCategory from 'shared/data/models/system-lists/id-verification-doc-category';
import idVerificationIssueDateValidationRule from 'data/models/system-lists/id-verification-issue-date-validation-rule';
import idVerificationExpiryDateValidationRule from 'data/models/system-lists/id-verification-expiry-date-validation-rule';
import idVerificationRequirementCategoryCompany from 'data/models/system-lists/id-verification-requirement-category-company';
import idVerificationRequirementCategoryPerson from 'data/models/system-lists/id-verification-requirement-category-person';
import listingRoomType from 'data/models/value-lists/listing-room-type';
import localAgency from 'data/models/value-lists/market-lead-agency';
import leadType from 'data/models/system-lists/lead-type';
import marketLeadOrigin from 'data/models/value-lists/market-lead-origin-type';
import marketLeadOriginListingStatus from 'data/models/system-lists/market-lead-origin-listing-status';
import marketLeadClaimBasis from 'data/models/system-lists/market-lead-claim-basis';
import marketLeadArchiveReason from 'data/models/system-lists/market-lead-archive-reason';
import workflowRunTypes from 'data/models/value-lists/workflow-run-types';
import workflowServices from 'data/models/value-lists/workflow-services';
import accountUsers from 'data/models/value-lists/account-users';
import antiMoneyLaunderingProviders from 'data/models/system-lists/anti-money-laundering-providers';
import campaignArchiveReason from 'data/models/system-lists/campaign-archive-reason';
import suburbs from 'data/models/system-lists/suburbs';
import ukSuburbs from 'data/models/system-lists/uk-suburbs';
import substantiationStatus from 'data/models/system-lists/substantiation-status';
import substantiationPosition from 'data/models/system-lists/substantiation-position';
import substantiationFundSource from 'data/models/system-lists/substantiation-fund-source';
import substantiationMethod from 'data/models/system-lists/substantiation-method';
import contractPurchtenantPosition from 'data/models/system-lists/purchaser-statuses';
import contractFallenReason from 'data/models/system-lists/fallen-reasons';
import referralStatus from 'data/models/system-lists/referral-status';
import commissionAmountModel from 'data/models/system-lists/commissions-commission-amount-model';
import washListNameSubstitutes from 'data/models/system-lists/wash-list-name-substitutes';
import chainArchiveReason from 'features/chains/data/chain-archive-reason';
import contractStabilityIndicator from 'data/models/system-lists/contract-stability-indicator';
import calendarEventCancellationReason from 'data/models/system-lists/calendar-event-cancellation-reason';
import calendarPermissions from 'data/models/system-lists/calendar-permissions';
import premiumListingRightmoveStampTextSale from 'data/models/system-lists/premium-listing-rightmove-stamp-text-sale';
import premiumListingRightmoveStampTextRental from 'data/models/system-lists/premium-listing-rightmove-stamp-text-rental';
import propertyCategory from 'data/models/system-lists/property-categories';
import mwrAttributeToUserIdTypeStandard from 'data/models/system-lists/mwr-attribute-to-user-id-type-standard';
import mwrAttributeToUserIdTypeReversal from 'data/models/system-lists/mwr-attribute-to-user-id-type-reversal';
import mwrAttributeToUserIdPresetStandard from 'data/models/system-lists/mwr-attribute-to-user-id-preset-standard';
import mwrAttributeToUserIdPresetReversal from 'data/models/system-lists/mwr-attribute-to-user-id-preset-reversal';
import mwrMetricLabelType from 'data/models/system-lists/mwr-metric-label-type';
import mwrServices from 'data/models/system-lists/mwr-services';
import mwrTimeTypeStandard from 'data/models/system-lists/mwr-time-type-standard';
import mwrTimeTypeReversal from 'data/models/system-lists/mwr-time-type-reversal';
import mwrTimePresetStandard from 'data/models/system-lists/mwr-time-preset-standard';
import mwrTimePresetReversal from 'data/models/system-lists/mwr-time-preset-reversal';
import mwrValueTypeStandard from 'data/models/system-lists/mwr-value-type-standard';
import mwrValueTypeReversal from 'data/models/system-lists/mwr-value-type-reversal';
import mwrValuePresetStandard from 'data/models/system-lists/mwr-value-preset-standard';
import mwrValuePresetReversal from 'data/models/system-lists/mwr-value-preset-reversal';
import interestLevel from 'data/models/system-lists/interest-level';
import listingFeedbackType from 'data/models/system-lists/listing-feedback-type';
import listingSystemState from 'data/models/system-lists/listing-system-state';
import officeLocations from 'data/models/system-lists/office-locations';
import listingCategory from 'data/models/system-lists/listing-category';
import contactPropertyRelnTypeWithOther from 'data/models/system-lists/contact-property-relationship-type-with-other';
import contactPropertyRelnType from 'data/models/system-lists/contact-property-relationship-type';
import contactListingRelnType from 'data/models/system-lists/contact-listing-relationship-type';
import contactPropertyRelnTypeWithCustom from 'data/models/system-lists/contact-property-relationship-type-with-custom';
import contactListingRelnTypeWithCustom from 'data/models/system-lists/contact-listing-relationship-type-with-custom';
import contactListingRelnTypeWithOther from 'data/models/system-lists/contact-listing-relationship-type-with-other';
import adminPropertyRelationshipTypes from 'data/models/entities/admin-property-relationship-types';
import adminListingRelationshipTypes from 'data/models/entities/admin-listing-relationship-types';
import customRelationshipTypeActions from 'data/models/system-lists/custom-relationship-type-actions';
import esignTemplateModule from 'data/models/system-lists/esign-template-module';
import keyLocations from 'data/models/system-lists/key-locations';
import privacyPreferencesActions from 'data/models/system-lists/privacy-preferences-actions';
import privacyConsentTypes from 'data/models/value-lists/privacy-consent-types';
import smsProviders from 'data/models/system-lists/sms-providers';
import emailProviders from 'data/models/system-lists/email-providers';
import dashboardsSoldStatus from 'features/dashboards/data/dashboards-sold-status';
import appointmentConfirmationSendVia from 'features/calendar/data/appointment-confirmation-send-via';
import followUpReminderDueDates from 'features/calendar/data/follow-up-reminder-due-dates';
import followUpReminderCustomDueDateUnits from 'features/calendar/data/follow-up-reminder-custom-due-date-units';
import reminderType from 'features/calendar/data/reminder-type';
import listingLinkType from 'data/models/value-lists/listing-link-type';
import chainVendorPosition from 'data/models/system-lists/vendor-statuses';
import appointmentTypeCategory from 'features/calendar/data/appointment-type-categories';
import tenancyApplications, {
  applicationAgreementType,
  applicationBondDepositType,
  applicationHoldingDepositStatus,
  applicationLeaseType,
  applicationPeriod,
  applicationTenantEmploymentStatus
} from 'features/tenancy-applications/data/tenancy-applications';
import embeddedAppTypes from 'data/models/value-lists/embedded-app-types';
import appraisalType from 'data/models/value-lists/appraisal-type';
import rentAmountPeriod from 'data/models/value-lists/rent-amount-period';
import rentAmountTax from 'data/models/value-lists/rent-amount-tax';
import sendSalesMemo from 'data/models/custom/send-sales-memo';
import epcModel from 'data/models/custom/epc';
import { persistSendSalesMemoFormValues } from 'data/models/custom/send-sales-memo-form-values';
import {
  matchCampaignFrequency,
  matchCampaignFrequencySms
} from 'data/models/system-lists/match-campaign-frequency';
import { dayOfWeek } from 'data/models/system-lists/day-of-week';
import commissionStructures from 'data/models/value-lists/commission-structures';
import reinzForms, {
  reinzCustomValuesDeposit,
  reinzNominee,
  reinzPriceBalanceOptions,
  reinzPropertyEstate,
  reinzPurchaserEmailForServiceNotices,
  reinzTemplateType,
  reinzVendorEmailForServiceNotices
} from 'features/reinz-forms/data/reinz-forms';
import {
  chartOfAccountsCategory,
  chartOfAccountsTaxType
} from 'features/finance/data/chart-of-accounts';
import appraisalArchiveReason from 'data/models/system-lists/appraisal-archive-reason';
import adminPipelineStages from 'features/pipelines/data/admin-pipeline-stages';
import agentSlidingScaleCalculationBase from 'data/models/system-lists/agent-sliding-scale-calculation-base';
import agentSlidingScaleCalculateListingStatus from 'data/models/system-lists/agent-sliding-scale-calculate-listing-status';
import contactTitle from 'data/models/system-lists/contact-title';
import subAccounts from 'data/models/value-lists/sub-accounts';
import adminNewsletterTemplates from 'features/newsletter-builder/data/admin-newsletter-templates';
import adminHtmlTemplates from 'data/models/entities/admin-html-templates';
import commWorksheetVersion from 'data/models/value-lists/comm-worksheet-version';
import trackModule from 'data/models/value-lists/track-module';
import adminSecurity from 'data/models/custom/admin-security';
import listingInsuranceType from 'data/models/value-lists/listing-insurance';
import contactAmlCheckStatus from 'data/models/value-lists/contact-aml-check-status';
// HACK: we want to clear entities and value lists when switching accounts
const combineModelsWithClear = (type, models) => (state, action) => {
  if (action.type === 'session/SWITCH_TO_ACCOUNT') {
    // Clear state on account switch
    state = undefined;
  }
  return combineModels(type, models)(state, action);
};

const entities = combineModelsWithClear('entities', {
  contacts,
  contracts,
  invoices,
  listings,
  campaigns,
  campaignMembers,
  listingPublication,
  postcodes,
  userProfile,
  addressLookup,
  adminUserValidationRules,
  adminIdentityDocumentTypes,
  adminIdentityRequirementsCompanies,
  adminIdentityRequirementsPeople,
  adminAppointmentTypes,
  adminBuyerMatch,
  embeddedApps,
  substantiations,
  accountSettingsCompliance,
  marketLeads,
  properties,
  buildings,
  contactAntiMoneyLaunderingChecks,
  contactIdentityVerifications,
  adminWorkflows,
  workflowInstances,
  adminCampaignTemplates,
  adminReferralCategories,
  referrals,
  adminCommGrossStructures,
  calendars,
  userCalendars,
  calendarEvents,
  chains,
  chainInstances,
  chainLinks,
  deals,
  leads,
  projects,
  projectStages,
  appraisals,
  listingPortalUploads,
  adminMetricWriteRules,
  metrics,
  adminUserGroups,
  adminDisclaimers,
  notes,
  reminders,
  feedback,
  newsletterCampaigns,
  adminPropertyRelationshipTypes,
  adminListingRelationshipTypes,
  keyRegister,
  adminKeyLocations,
  mailMerge,
  savedFilters,
  customReports,
  reportingDashboards,
  activities,
  tenancyApplications,
  adminPipelineStages,
  adminNewsletterTemplates,
  adminHtmlTemplates,
  reinzForms
});

const valueLists = combineModelsWithClear('valueLists', {
  enquiryMethod,
  enquirySource,
  idVerificationDocCategory,
  idVerificationIssueDateValidationRule,
  idVerificationExpiryDateValidationRule,
  idVerificationRequirementCategoryCompany,
  idVerificationRequirementCategoryPerson,
  listingRoomType,
  adminUserValidationServices,
  adminUserValidationTriggerMap,
  leadType,
  localAgency,
  marketLeadOrigin,
  marketLeadOriginListingStatus,
  marketLeadClaimBasis,
  marketLeadArchiveReason,
  contactCompanyRelnType,
  substantiationStatus,
  substantiationPosition,
  substantiationFundSource,
  substantiationMethod,
  commissionAmountModel,
  contactTitle,
  campaignType,
  campaignStatus,
  campaignRecordBaseStatus,
  workflowRunTypes,
  workflowServices,
  antiMoneyLaunderingProviders,
  accountUsers,
  campaignArchiveReason,
  suburbs,
  ukSuburbs,
  contractPurchtenantPosition,
  contractFallenReason,
  referralStatus,
  washListNameSubstitutes,
  chainArchiveReason,
  contractStabilityIndicator,
  calendarEventCancellationReason,
  calendarPermissions,
  premiumListingRightmoveStampTextSale,
  premiumListingRightmoveStampTextRental,
  propertyCategory,
  mwrAttributeToUserIdTypeStandard,
  mwrAttributeToUserIdTypeReversal,
  mwrAttributeToUserIdPresetStandard,
  mwrAttributeToUserIdPresetReversal,
  mwrMetricLabelType,
  mwrServices,
  mwrTimeTypeStandard,
  mwrTimeTypeReversal,
  mwrTimePresetStandard,
  mwrTimePresetReversal,
  mwrValueTypeStandard,
  mwrValueTypeReversal,
  mwrValuePresetStandard,
  mwrValuePresetReversal,
  interestLevel,
  listingFeedbackType,
  listingSystemState,
  listingInsuranceType,
  officeLocations,
  listingCategory,
  contactPropertyRelnTypeWithCustom,
  contactPropertyRelnTypeWithOther,
  contactPropertyRelnType,
  contactListingRelnType,
  contactListingRelnTypeWithCustom,
  contactListingRelnTypeWithOther,
  customRelationshipTypeActions,
  esignTemplateModule,
  keyLocations,
  privacyPreferencesActions,
  privacyConsentTypes,
  dashboardsSoldStatus,
  appointmentConfirmationSendVia,
  smsProviders,
  emailProviders,
  followUpReminderDueDates,
  followUpReminderCustomDueDateUnits,
  reminderType,
  listingLinkType,
  chainVendorPosition,
  appointmentTypeCategory,
  embeddedAppTypes,
  applicationLeaseType,
  applicationAgreementType,
  applicationPeriod,
  applicationTenantEmploymentStatus,
  applicationBondDepositType,
  matchCampaignFrequency,
  matchCampaignFrequencySms,
  appraisalType,
  subAccounts,
  rentAmountPeriod,
  rentAmountTax,
  commissionStructures,
  dayOfWeek,
  reinzTemplateType,
  reinzPropertyEstate,
  reinzNominee,
  reinzCustomValuesDeposit,
  reinzPriceBalanceOptions,
  appraisalArchiveReason,
  reinzVendorEmailForServiceNotices,
  reinzPurchaserEmailForServiceNotices,
  chartOfAccountsTaxType,
  chartOfAccountsCategory,
  agentSlidingScaleCalculationBase,
  agentSlidingScaleCalculateListingStatus,
  commWorksheetVersion,
  trackModule,
  contactAmlCheckStatus,
  applicationHoldingDepositStatus
});

// Persisted models
const persistAuth = persistReducer(
  {
    key: 'rex.auth',
    storage: new CookieStorage(Cookies, {
      setCookieOptions: { domain: config.COOKIE_URL }
    })
  },
  auth
);

const persistPafUpload = persistReducer(
  {
    key: 'rex.pafUpload',
    storage: localForage
  },
  pafUpload
);

const persistUI = persistReducer(
  {
    key: 'rex.ui',
    storage: localForage,
    whitelist: ['showLagRadar']
  },
  ui
);

const persistDashboardFilter = persistReducer(
  {
    key: 'rex.dashboardFilter',
    storage: localForage
  },
  dashboardFilter
);

/*
| Setup for Stores
|------------------------
*/
let reducers = combineReducers({
  auth: persistAuth,
  connection,
  whereabouts: whereaboutsReducer,
  apiCache,
  nav,
  session,
  ui: persistUI,
  announcements,
  entities,
  valueLists,
  pafUpload: persistPafUpload,
  recordStream,
  washLists,
  collisionAvoidance,
  connectableCalendars,
  thirdPartyServiceEaPrint,
  thirdPartyServiceClickToDial,
  ThirdPartyServiceHelloSign,
  ThirdPartyServiceDocuSign,
  adminMergeTemplates,
  adminSecurity,
  notification,
  telephony,
  externalAddress,
  ThirdPartyServiceSpokeSocial,
  privacySettings,
  contactPrivacy,
  adminOutboundSettings,
  dashboardReportingData,
  dashboardFilter: persistDashboardFilter,
  reportingFilters: persistReportingFilters,
  sendSalesMemo,
  epcModel,
  sendSalesMemoFormValues: persistSendSalesMemoFormValues
});

if (config.ENABLE_REDUX_STATE_CHECK) {
  const originalReducers = reducers;
  reducers = (state = undefined, action) => {
    const result = originalReducers(state, action);
    JSON.stringify(result, (key, value) => {
      switch (typeof value) {
        case 'object': {
          if (!value) {
            break;
          }
          const prototype = Object.getPrototypeOf(value);
          if (!prototype) {
            break;
          }
          if (prototype === Object.prototype) {
            break;
          }
          if (prototype === Array.prototype) {
            break;
          }
          const ctor = prototype.constructor.name || 'Unknown';
          const desc =
            value instanceof Object ? ctor : `${ctor} in another realm`;
          console.error(
            `Found object in state; key = ${key}; ctor = ${desc}; action = ${action.type}`
          );
          break;
        }
        case 'function': {
          const desc =
            value instanceof Function
              ? value.toString()
              : 'Function in another realm';
          console.error(
            `Found function in state; key = ${key}; function = ${desc}; action = ${action.type}`
          );
          break;
        }
        default:
          break;
      }
      return value;
    });
    return result;
  };
}

const store = configureStore(reducers, [
  uiMiddleware,
  notificationMiddleware(),
  collisionAvoidanceMiddleware(),
  whereaboutsMiddleware,
  apiClientMiddleware(setAuthToken),
  connectionMiddleware,
  bridgesMiddleware,
  bridgeEntityMiddleware
]);

export const persistedStore = persistStore(store, null, () => {
  store.dispatch(session.actionCreators.init()).catch(console.error);
});

window.purgePersistedStore = persistedStore.purge;

export { store };
