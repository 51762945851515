import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { Id } from '@rexlabs/model-generator';
import { NoteItem } from '../entities/notes';
import dayjs from 'dayjs';
import { queryOptions } from '@tanstack/react-query';
import { apiResult } from 'shared/utils/api-client/api-result';
import { mutationOptions } from 'src/lib/react-query';

export type RecordStreamServiceName =
  | 'Listings'
  | 'Contacts'
  | 'Properties'
  | 'ProjectStages'
  | 'Projects';

export interface LatestNoteData {
  date: string;
  text: string;
  type: string;
}

export interface PinRecordPayload {
  pinned_record_id: string;
  record_id: string;
  service_name: string;
  source_service: string;
  is_pinned?: boolean;
}

export interface GetRecordStreamPayload {
  source_services: string[];
  service_name: string;
  record_id: string;
  limit: number;
}

export interface PinnedRecord<Record = unknown> {
  id: number;
  is_pinned: boolean;
  record: Record;
}

const modelKey = 'recordStream';

export const recordStreamQueries = {
  getRecordStream({
    params,
    onError
  }: {
    params: GetRecordStreamPayload;
    onError?: (error: Error) => void;
  }) {
    const { service_name, source_services, limit, record_id } = params;
    return queryOptions({
      queryKey: [
        modelKey,
        'getRecordStream',
        service_name,
        ...source_services,
        limit,
        record_id
      ],
      queryFn: async () => {
        try {
          return await apiResult<PinnedRecord[]>(
            `RecordStream::getRecordStream`,
            params
          );
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      }
    });
  },
  togglePinRecord({
    params,
    onError,
    onSuccess
  }: {
    params: { service_name: string };
    onError?: (error: Error) => void;
    onSuccess: () => void;
  }) {
    const { service_name } = params;
    return mutationOptions({
      onError,
      onSuccess,
      mutationKey: [modelKey, 'pinRecord', service_name],
      mutationFn: (values: PinRecordPayload) => {
        const action = values.is_pinned ? 'unpinRecord' : 'pinRecord';
        delete values.is_pinned;
        return apiResult<boolean>(`RecordStream::${action}`, values);
      }
    });
  }
};

const actionCreators = {
  getLatestNote: {
    request: ({
      id,
      serviceName
    }: {
      id: Id;
      serviceName: RecordStreamServiceName;
    }) =>
      api
        .post('RecordStream::getRecordStream', {
          service_name: serviceName,
          source_services: ['Notes'],
          record_id: id,
          limit: 1
        })
        .then(({ data }) => {
          const streamEntry: { record: NoteItem; timestamp: number } =
            data?.result?.[0];

          if (!streamEntry) {
            return;
          }

          const note: LatestNoteData = {
            type: 'Note',
            text: streamEntry.record.note,
            date: dayjs.unix(streamEntry?.timestamp).format('DD MMM YYYY')
          };

          if (note.text) {
            const maxNoteLength = 70;
            if (note.text.length > maxNoteLength) {
              note.text = note.text.slice(0, maxNoteLength) + '...';
            }
          }
          return note;
        }),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator<LatestNoteData, typeof actionCreators>(
  modelKey
).createModel({
  actionCreators
});
