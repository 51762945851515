import { Fragment, useMemo, useState } from 'react';
import documentUploader from 'view/components/upload/file-upload-multi/upload-api';
import Box from '@rexlabs/box';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { Body } from 'components/text/body';
import { LoadingButton } from 'shared/components/button/index';
import { COLORS } from 'theme';
import { Tag } from 'components/text/tag';
import { Link } from 'components/text/link';
import { IconButton } from 'view/components/button/index';
import FileUploadInput, {
  FileUploadInputProps
} from '@rexlabs/file-upload-input';
import { ICONS } from 'shared/components/icon';

interface FileUploadButtonInputProps extends FileUploadInputProps {
  onChange: (e: any) => void;
  value:
    | { name: string; url: string; uri: string }
    | { name: string; url: string; uri: string }[];
  isArray?: boolean;
  disabled?: boolean;
}

const inputContainerStyles = {
  InputContainer: StyleSheet({
    container: {
      // HACK: to increase specificity of selector
      '&&': {
        display: 'none'
      }
    }
  })
};
export function FileUploadButtonInput(props: FileUploadButtonInputProps) {
  const { onChange, isArray = true, ...rest } = props;

  const value = Array.isArray(props.value) ? props.value[0] : props.value;

  const [isUploading, setIsUploading] = useState(false);

  const createFakeEvent = (fakeFile) => {
    return {
      persist: () => null,
      target: {
        type: 'file',
        name: fakeFile?.name,
        id: fakeFile?.name,
        files: fakeFile ? (isArray ? [fakeFile] : fakeFile) : null
      }
    };
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];

    if (file && !file.uri) {
      setIsUploading(true);

      const uploadResult = await documentUploader(file);
      const fakeFile = {
        uri: uploadResult.uri,
        url: uploadResult.url,
        name: file.name
      };

      onChange(createFakeEvent(fakeFile));

      setIsUploading(false);
    }
  };

  const fileName = useMemo(() => {
    if (!value) return '';
    if (value.name) return value.name;
    if (value.uri) return value.uri.split('/').pop();
    return '';
  }, [value]);

  return (
    <FileUploadInput {...rest} value={value} onChange={handleChange}>
      {(input) => {
        const {
          inputProps,
          dragEvents,
          InputContainer,
          triggerInput,
          removeFiles
        } = input;
        return (
          <Box flex={1} flexDirection='row' alignItems='center'>
            <StylesProvider
              components={inputContainerStyles}
              prioritiseParentStyles={false}
            >
              <InputContainer {...inputProps} {...dragEvents} />
            </StylesProvider>

            {!value || isUploading ? (
              <Fragment>
                <Box mr={10}>
                  <Body italic regular>
                    No File
                  </Body>
                </Box>
                <LoadingButton
                  dark
                  padding={true}
                  isLoading={isUploading}
                  style={{
                    height: '34px'
                  }}
                  onClick={(e) => {
                    triggerInput();
                    e.stopPropagation();
                  }}
                >
                  Choose a File
                </LoadingButton>
              </Fragment>
            ) : null}

            {value && !isUploading ? (
              <Fragment>
                <Box
                  mr={10}
                  flex={1}
                  flexDirection='row'
                  alignItems='center'
                  style={{
                    borderRadius: '5px',
                    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
                    padding: '10px 15px'
                  }}
                >
                  <Tag>{fileName}</Tag>
                  {value.url ? (
                    <Link
                      blue
                      undecorated
                      style={{ marginLeft: '10px' }}
                      href={value.url}
                      target='_blank'
                      rel='noreferrer noopener'
                      onClick={(e) => e.preventDefault()}
                    >
                      View
                    </Link>
                  ) : null}
                </Box>
                <IconButton
                  red
                  circle
                  isDisabled={props.disabled ?? false}
                  Icon={ICONS.REMOVE}
                  onClick={() => {
                    onChange(createFakeEvent(null));
                    removeFiles();
                  }}
                />
              </Fragment>
            ) : null}
          </Box>
        );
      }}
    </FileUploadInput>
  );
}
